import { atom } from 'jotai'

export const purchaseItemsAtom = atom({})
purchaseItemsAtom.debugLabel = 'purchaseItems'

// 追加、更新、削除用に1件だけ保持する
export const purchaseItemAtom = atom({})
purchaseItemAtom.debugLabel = 'purchaseItem'

export const createPurchaseItemAtom = atom(
  (get) => !!get(purchaseItemAtom),
  (get, set) => {
    const newPurchaseItem = get(purchaseItemAtom)
    if (newPurchaseItem && newPurchaseItem.distribution_id) {
      set(purchaseItemsAtom, (prev) => [...prev, newPurchaseItem])
      set(purchaseItemAtom, {})
    }
  },
)

export const purchaseItemsArrayAtom = atom((get) => {
  const purchaseItems = get(purchaseItemsAtom)
  if (!purchaseItems || Object.keys(purchaseItems).length === 0) return []

  return Object.values(purchaseItems).map((item) => item)
})
