import { PropTypes } from 'prop-types'

import { Stack } from '@chakra-ui/react'

import { NewScrollListTitles as ScrollableList, SectionTitle } from '@!/Store/Common/components/'

/* 横スクロール型の新刊リスト */
export const ScrollableNewBookList = ({ newBooks, tabName }) => {
  const sectionTitle = '待望の新着コミックス'
  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={sectionTitle} />
      <ScrollableList titles={newBooks} type="book" sectionTitle={`${tabName}_${sectionTitle}`} />
    </Stack>
  )
}

ScrollableNewBookList.propTypes = {
  newBooks: PropTypes.array.isRequired,
  tabName: PropTypes.string.isRequired,
}
