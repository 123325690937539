import { useQuery } from 'react-query'

import { checkPurchasedBooksApi } from '@!/Api/User'
import { userCheckPurchasedBookKeys } from '@!/Api/queryKeys'
import { sliceByNumber } from '@!/lib/Array'

export const useCheckPurchasedBooks = (userHash, distributionIds) => {
  return useQuery({
    queryKey: userCheckPurchasedBookKeys.check(userHash, distributionIds),
    queryFn: async () => {
      // 414 URI Too Large は2000文字程度で発生する(サーバーの設定による)
      // distributionIdはuuidフォーマットなので36文字
      // 2000 / 36 = 55.555...なので50件ずつ程度の取得にとどめる
      const splitedDistributionIds = sliceByNumber(distributionIds, 50)
      const purchasedIds = await Promise.all(splitedDistributionIds.map(async (ids) => await checkPurchasedBooksApi(ids)))
      return purchasedIds.flat()
    },
    enabled: !!userHash && distributionIds.length > 0,
  })
}
