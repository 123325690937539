import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { removeCartItemsApi } from '@!/Api/Cart'
import { cartItemsKeys } from '@!/Api/queryKeys'

export const useRemoveCartItems = (userHash) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [apiError, setApiError] = useState(null)

  const queryClient = useQueryClient()
  const removeCartItemsMutation = useMutation({
    mutationFn: (params) => removeCartItemsApi(params),
    onMutate: async (params) => {
      const previousData = queryClient.getQueryData(cartItemsKeys.cartItemsLoggedIn(userHash))
      queryClient.setQueryData(cartItemsKeys.cartItemsLoggedIn(userHash), (old) => {
        if (old && old.length > 0) {
          return old.filter((item) =>
            params.some((distributionId) => {
              distributionId === item.distribution_id
            }),
          )
        }

        return old
      })
      return { previousData }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(cartItemsKeys.cartItemsLoggedIn(userHash), context.previousData)

      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('server error')
      }

      if (error.status === 422) {
        setRedirectUrl('/unauthorized')
      }
    },
    onSettled: () => {
      // API実行終了時はreact-queryのキャッシュをクリアする
      queryClient.invalidateQueries(cartItemsKeys.cartItemsLoggedIn(userHash))
    },
  })

  return [removeCartItemsMutation, redirectUrl, apiError]
}
