import { useEffect } from 'react'
import { Box, Center, Link } from '@chakra-ui/react'

import { useResetByLogin } from '@!/Hooks'

export const ActivationComplete = () => {
  const executeResetData = useResetByLogin()

  useEffect(() => {
    executeResetData()
  }, [])

  return (
    <Box>
      <Center pt={4} mb={4}>
        メール認証を完了しました。
        <br />
        引き続きマンガBANGブックスをお楽しみください
      </Center>
      <Center pb={4}>
        <Link href="/">ホームへ</Link>
      </Center>
    </Box>
  )
}
