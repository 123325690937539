import { PropTypes } from 'prop-types'

import { Center, Text, VStack } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'
import { goFirstPage, searchNext, closeViewer } from '@!/Colophon/components/handleViewer'

export const Colophon = ({ storeBookUrl, isSample }) => {
  return (
    <Center bg="white" width="full" height="full" position="absolute">
      <VStack spacing={0}>
        <Text mb="1rem">こちらが最終ページになります。</Text>
        <PrimaryButton mb="0.8rem" variant="outline" onClick={goFirstPage}>
          先頭へ戻る
        </PrimaryButton>
        {isSample == 'true' && storeBookUrl && (
          <PrimaryButton mb="0.8rem" variant="outline" as="a" href={storeBookUrl} target="_parent">
            本編を読む
          </PrimaryButton>
        )}
        <PrimaryButton mb="0.8rem" variant="outline" onClick={searchNext}>
          次話検索
        </PrimaryButton>
        <PrimaryButton variant="outline" onClick={closeViewer}>
          ビューアを終了
        </PrimaryButton>
      </VStack>
    </Center>
  )
}

Colophon.propTypes = {
  storeBookUrl: PropTypes.string,
  isSample: PropTypes.string,
}
