import { useRef, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { checkEmailAddress, checkPassword } from '@!/Util/String'
import { CircularProgressModal } from '@!/components/Modal'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useRegisterAccountEmail } from '@!/Hooks'
import { alertMessageAtom } from '@!/atoms/appSettingsAtom'
import { Container, VStack, Heading, Box, Text, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'

const AccountRegisterEmail = () => {
  const navigate = useNavigate()
  const [registerAccountEmailMutation, registrationStatus] = useRegisterAccountEmail()
  const [validate, setValidate] = useState({})
  const [, setAlertMessage] = useAtom(alertMessageAtom)

  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const confirmPasswordRef = useRef(null)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'メールアドレス登録' }])
  }, [])

  useEffect(() => {
    if (registrationStatus === 'success') {
      setAlertMessage('ご入力頂いたメールアドレスに認証用のメールを送らせていただきました。')
      navigate('/user/account')
    }
  }, [registrationStatus])

  const registration = (e) => {
    e.preventDefault()

    setValidate({})

    const email = emailRef.current.value
    const password = passwordRef.current.value
    const confirmPassword = confirmPasswordRef.current.value

    let errors = {}

    if (!checkEmailAddress(email)) {
      errors.email = 'メールアドレスが正しくありません。'
    }

    if (!checkPassword(password)) {
      errors.password = 'パスワードが正しくありません。\nパスワードは半角英数字8文字以上かつ英数字それぞれ1文字以上のものを入力してください。'
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = 'パスワードと確認用のパスワードが一致しません。'
    }

    if (Object.keys(errors).length > 0) {
      setValidate(errors)
      return
    }

    registerAccountEmailMutation.mutate({ email: email, password: password })
  }

  const isError = (field) => !!validate[field]
  const errorMessage = (field) => {
    if (isError(field)) {
      return <FormErrorMessage whiteSpace="pre-line">{validate[field]}</FormErrorMessage>
    }
    return null
  }

  return (
    <Container p={5} bg="bg.primary" boxSizing="border-box">
      <Box my={8}>
        <Heading size="md" as="h2" textAlign="center" mb={6}>
          メールアドレス登録
        </Heading>
        <CircularProgressModal isOpen={registerAccountEmailMutation.isLoading} />
        <form onSubmit={registration}>
          <VStack spacing={0}>
            <FormControl isRequired isInvalid={isError('email')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                メールアドレス
              </FormLabel>
              <Input type="email" ref={emailRef} name="email" placeholder="メールアドレス" bg="bg.secondary" boxSizing="border-box" />
              {errorMessage('email')}
            </FormControl>
            <FormControl isRequired isInvalid={isError('password')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                パスワード
              </FormLabel>
              <Input type="password" ref={passwordRef} name="password" placeholder="パスワード" bg="bg.secondary" boxSizing="border-box" />
              <Text fontSize="xs" color="label.secondary" mt={1}>
                半角英数字8文字以上
              </Text>
              {errorMessage('password')}
            </FormControl>
            <FormControl isRequired isInvalid={isError('confirmPassword')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                パスワード(確認)
              </FormLabel>
              <Input type="password" ref={confirmPasswordRef} name="password" placeholder="パスワード(確認)" bg="bg.secondary" boxSizing="border-box" />
              {errorMessage('confirmPassword')}
            </FormControl>
            {registrationStatus === 'fail' && (
              <Text color="label.accent" fontSize="sm" my={3}>
                登録に失敗しました。
              </Text>
            )}
            <PrimaryButton type="submit" my={3}>
              登録
            </PrimaryButton>
          </VStack>
        </form>
      </Box>
    </Container>
  )
}

export default AccountRegisterEmail
