import { PropTypes } from 'prop-types'

import { PrimaryButton } from '@!/components/Button'
import { buildUrlString } from '@!/Util'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useNavigationHandler } from '@!/Hooks/'

export const BuyNowButton = ({ volume }) => {
  const [userHash] = useAtom(userHashAtom)
  const { redirectToLoginWithToast } = useNavigationHandler()

  const handleClick = () => {
    if (userHash === undefined) {
      redirectToLoginWithToast()
    } else {
      window.location.href = buildUrlString('/store/purchase', { book_id: volume.distribution_id })
    }
  }

  return (
    <>
      <PrimaryButton width="100%" height="37px" as="a" onClick={handleClick}>
        今すぐ購入する
      </PrimaryButton>
    </>
  )
}

BuyNowButton.propType = {
  volume: PropTypes.object.isRequired,
}
