import { useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { Box, HStack, Stack, useToast, Divider, Wrap, Heading, Center } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { alertMessageAtom, menuOpenAtom } from '@!/atoms/appSettingsAtom'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'
import { HomeBanner, CoinPurchaseBanner, LimitedTimeBanner } from '@!/components/Banner'
import { SearchForm, TagSearchBox } from '@!/Store/Common/containers'
import { ScrollableNewBookList, ScrollableRankingList, ScrollableBulkPurchaseTitleList, TagItem } from '@!/Store/Home/components'
import { PrimaryButton } from '@!/components/Button'
import { getDevice } from '@!/Util/Device'
import FeatureListWithBanners from '@!/Store/Home/components/FeatureListWithBanners'
import { TopTabs } from '@!/Store/Common/components'
import { useTabs } from '@!/Hooks'

/* ストアのホーム画面
   現在はサイト全体のホーム
 */
export const HomePage = ({ home, tabName, limitedTimeBanner }) => {
  const toast = useToast()
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom)
  const rankingBookTitles = home.ranking_book_titles || []
  const newBooks = home.new_books || []
  const bulkPurchaseTitles = home.bulk_purchase_titles || []
  const features = home.store_features || []
  const searchTags = home.search_tags || []
  const webFeatures = home.web_features
  const [userHash] = useAtom(userHashAtom)
  const [safeSearch] = useAtom(safeSearchAtom)
  const isLoggedIn = useMemo(() => !!userHash, [userHash])
  const device = useMemo(() => getDevice(), [])
  const { currentTab } = useTabs(tabName)
  const genreName = currentTab.genreName
  const genreLabel = currentTab.genreLabel

  // 会員登録完了メッセージ
  useEffect(() => {
    if (alertMessage) {
      toast({
        title: 'Notification',
        description: alertMessage,
        status: 'info',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAlertMessage(null)
        },
      })
    }
  }, [alertMessage])

  const tags = webFeatures
    ? [
        { path: `/store/features/${webFeatures.campaign}`, name: 'キャンペーン' },
        { path: `/store/features/${webFeatures.free}`, name: '無料' },
      ]
    : []

  const [, setIsMenuOpen] = useAtom(menuOpenAtom)

  const handleSafeSearchClick = () => {
    setIsMenuOpen(true)
  }

  return (
    <>
      <TopTabs tabName={tabName} />
      <Box>
        <Box px={2} pt={4}>
          {limitedTimeBanner && tabName === 'home' ? <LimitedTimeBanner bannerInfo={limitedTimeBanner} /> : <HomeBanner tabName={tabName} />}
        </Box>
        <SearchForm genreName={genreName} placeholder={`${genreLabel}のマンガを検索`} />
        <Divider />
        <Wrap spacing={0} p={3} justify="center">
          {tags.map((tag, index) => (
            <Box key={index}>
              <TagItem name={tag.name} whiteSpace="nowrap" as="a" href={tag.path} />
            </Box>
          ))}
          <Box cursor="pointer" onClick={handleSafeSearchClick}>
            <TagItem name={safeSearch ? 'セーフサーチ：ON' : 'セーフサーチ：OFF'} />
          </Box>
        </Wrap>
        {!isLoggedIn ? (
          <HStack spacing={0} px={4}>
            <PrimaryButton variant="outline" as="a" href="/user/login" mr="11px">
              ログイン
            </PrimaryButton>
            <PrimaryButton as="a" href="/user/login#/registrations">
              簡単！無料会員登録
            </PrimaryButton>
          </HStack>
        ) : (
          <CoinPurchaseBanner device={device} />
        )}
        <Heading as="h1" fontSize="sm" textAlign="center" my={2}>
          ↓【注目】全作品40%ポイント還元↓
        </Heading>
        <Divider />
        <Stack spacing={0}>
          {rankingBookTitles.length > 0 && <ScrollableRankingList rankingBookTitles={rankingBookTitles} tabName={tabName} />}
          {newBooks.length > 0 && <ScrollableNewBookList newBooks={newBooks} tabName={tabName} />}
          {bulkPurchaseTitles.length > 0 && <ScrollableBulkPurchaseTitleList titles={bulkPurchaseTitles} tabName={tabName} />}
          <FeatureListWithBanners features={features} tabName={tabName} />
        </Stack>
        <Divider my={6} borderColor="bg.secondary" borderBottomWidth="12px" />
        <TagSearchBox searchTags={searchTags} genreName={genreName} />
        {genreName && (
          <Box pb={6}>
            <SearchForm genreName={genreName} placeholder={`${genreLabel}のマンガを検索`} />
            <Center>
              <Box cursor="pointer" onClick={handleSafeSearchClick}>
                <TagItem name={safeSearch ? 'セーフサーチ：ON' : 'セーフサーチ：OFF'} />
              </Box>
            </Center>
          </Box>
        )}
      </Box>
    </>
  )
}

HomePage.propTypes = {
  home: PropTypes.object.isRequired,
  tabName: PropTypes.string.isRequired,
  limitedTimeBanner: PropTypes.object,
}
