import PropTypes from 'prop-types'
import { CartIcon, ViewCartIcon } from '@!/components/Icon'
import { NormalButton, NormalIconButton } from '@!/components/Button'
import { HStack } from '@chakra-ui/react'

export const ViewCartButton = ({ iconOnly }) => {
  const handleButtonClick = (e) => {
    e.preventDefault()
    window.location.href = '/cart'
  }

  const handleIconClick = (e) => {
    e.preventDefault()
  }

  return (
    <>
      {iconOnly ? (
        <HStack spacing={0}>
          <ViewCartIcon width="80px" height="23px" mr={1} onClick={handleIconClick} />
          <NormalIconButton icon={<CartIcon width="1.5em" height="1.5em" />} onClick={handleButtonClick} aria-label="カートを見る" />
        </HStack>
      ) : (
        <NormalButton as="a" href="/cart" width="100%" height="37px" rightIcon={<CartIcon width="1.5em" height="1.5em" ml={-1} />}>
          カートを見る
        </NormalButton>
      )}
    </>
  )
}

ViewCartButton.propTypes = {
  iconOnly: PropTypes.bool,
}

ViewCartButton.defaultProps = {
  iconOnly: false,
}
