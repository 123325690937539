import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { Box, Center } from '@chakra-ui/react'

import { Config } from '@!/config/config'
import { Paginate } from '@!/components/Paginate'
import { usePurchaseItems } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { BookPurchaseHistoryListTable } from '@!/User/BookPurchaseHistoryList/components'

const BookPurchaseHistoryList = () => {
  const [, purchaseHistories] = usePurchaseItems()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  const [currentPage, setCurrentPage] = useState(1)
  const [showingHistories, setShowingHistories] = useState([])
  const perPage = Config.perPage

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'ストア購入履歴' }])
  }, [])

  useEffect(() => {
    const start = (currentPage - 1) * perPage
    const end = start + perPage
    if (purchaseHistories) {
      setShowingHistories(purchaseHistories.histories.slice(start, end))
    }
  }, [purchaseHistories, currentPage])

  return (
    <Box pt="24px" pb="47px">
      <BookPurchaseHistoryListTable histories={showingHistories} />
      {purchaseHistories && purchaseHistories.histories.length > 0 && (
        <Center mt="24px">
          <Paginate currentPage={currentPage} totalCount={purchaseHistories.histories.length} perPage={perPage} onPageChange={onPageChange} />
        </Center>
      )}
    </Box>
  )
}

export default BookPurchaseHistoryList
