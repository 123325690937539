import { useEffect, useState, useMemo } from 'react'
import { Link, HStack } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { css } from 'aphrodite'
import { styles } from '../styles/PaymentStyle'

import { useSearchCard } from '@!/Hooks/useSearchCard'
import { execPurchaseCarrierApi, execPurchasePaypayApi } from '@!/Api/Payment'
import CheckPng from '@images/check.png'
import CardPng from '@images/card.png'
import DocomoPng from '@images/docomo.png'
import AuPng from '@images/au.png'
import SoftbankPng from '@images/softbank.png'
import PaypayPng from '@images/paypay.png'
import { buildAssetUrlString } from '@!/Util'
import { paymentListAtom, listIndexAtom, cardInfoAtom, apiTimeAtom, accessIdAtom, accessTokenAtom, payStartUrlAtom, payTypeAtom } from '@!/atoms/paymentAtom'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'
import { userHashAtom } from '@!/atoms/userInfoAtom'

export const PaymentSelect = () => {
  const navigate = useNavigate()
  const [listIndex, setListIndex] = useAtom(listIndexAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const [cardInfo] = useAtom(cardInfoAtom)
  const [apiTime] = useAtom(apiTimeAtom)
  const [accessId, setAccessId] = useAtom(accessIdAtom)
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
  const [, setPayStartUrl] = useAtom(payStartUrlAtom)
  const [, setPayType] = useAtom(payTypeAtom)

  const [isProcessing, setIsProcessing] = useState(true)
  const [apiError, setApiError] = useState(null)

  const [isInValidPaymentListItem] = useValidListItemCheck()
  const coin = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][0]
  }, [isInValidPaymentListItem, paymentList, listIndex])
  const price = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][1]
  }, [isInValidPaymentListItem, paymentList, listIndex])

  const [userHash] = useAtom(userHashAtom)
  const [searchCardMutation, cardInfoError] = useSearchCard(userHash)

  const checkImg = useMemo(() => {
    return buildAssetUrlString(CheckPng)
  }, [])
  const cardImg = useMemo(() => {
    return buildAssetUrlString(CardPng)
  }, [])
  const docomoImg = useMemo(() => {
    return buildAssetUrlString(DocomoPng)
  }, [])
  const auImg = useMemo(() => {
    return buildAssetUrlString(AuPng)
  }, [])
  const softbankImg = useMemo(() => {
    return buildAssetUrlString(SoftbankPng)
  }, [])
  const paypayImg = useMemo(() => {
    return buildAssetUrlString(PaypayPng)
  }, [])

  // キャリア決済API
  const execPurchaseCarrierMutation = useMutation({
    mutationFn: (params) => execPurchaseCarrierApi(params),
    onSuccess: (data) => {
      setAccessId(data.access_id)
      setAccessToken(data.token)
      setPayStartUrl(data.start_url)
      setIsProcessing(false)
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('etc_error')
      }
      setIsProcessing(false)
    },
  })

  // PayPay決済API
  const execPurchasePaypayMutation = useMutation({
    mutationFn: (params) => execPurchasePaypayApi(params),
    onSuccess: (data) => {
      setAccessId(data.access_id)
      setAccessToken(data.token)
      setPayStartUrl(data.start_url)
      setIsProcessing(false)
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('etc_error')
      }
      setIsProcessing(false)
    },
  })

  useEffect(() => {
    setIsProcessing(searchCardMutation.isLoading)
  }, [searchCardMutation.isLoading])

  useEffect(() => {
    if (cardInfoError) {
      setApiError(cardInfoError)
    }
  }, [cardInfoError])

  const handlePurchase = (payType) => {
    if (isProcessing) return

    setPayType(payType)

    if (payType === 'paypay') {
      execPurchasePaypayMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
    } else {
      execPurchaseCarrierMutation.mutate({ listIndex: listIndex, apiTime: apiTime, carrier: payType })
    }
  }

  useEffect(() => {
    if (isProcessing || !accessId || !accessToken || apiError) {
      return
    }

    navigate('/redirect')
  }, [isProcessing, accessId, accessToken, apiError])

  const pageBack = (e) => {
    e.preventDefault()
    setListIndex(-1)
    navigate(-1)
  }

  const handleDialog = () => {
    setListIndex(-1)
    location.href = '/user/payment'
  }

  const creditCardLink = cardInfo && Object.keys(cardInfo).length > 0 ? '/confirm' : '/new_card'

  return (
    <div className={css(styles.credit_card_main)}>
      <div>
        <div className={css(styles.select_form_group)}>
          <label className={css(styles.confirm_card_label)}>購入コイン数</label>
          <p className={css(styles.credit_card_coin)}>{coin}</p>
        </div>

        <div className={css(styles.select_form_group)}>
          <label className={css(styles.confirm_card_label)}>お支払い金額</label>
          <p className={css(styles.credit_card_price)}>￥{price}</p>
        </div>
      </div>
      <div className={css(styles.select_back)}>
        <a className={css(styles.confirm_back_link)} href="#" onClick={(e) => pageBack(e)}>
          {'< 前のページに戻る'}
        </a>
      </div>
      <div className={css(styles.select_group_header)}>購入方法を選択してください</div>
      <div className={css(styles.select_group)}>
        <RouterLink to={creditCardLink}>
          <HStack>
            <img className={css(styles.select_check)} src={checkImg} />
            <img className={css(styles.select_logo_image)} src={cardImg} />
          </HStack>
        </RouterLink>
      </div>
      <div className={css(styles.select_group)}>
        <Link onClick={() => handlePurchase('docomo')}>
          <HStack>
            <img className={css(styles.select_check)} src={checkImg} />
            <img className={css(styles.select_logo_image)} src={docomoImg} />
          </HStack>
        </Link>
      </div>
      <div className={css(styles.select_group)}>
        <Link onClick={() => handlePurchase('au')}>
          <HStack>
            <img className={css(styles.select_check)} src={checkImg} />
            <img className={css(styles.select_logo_image)} src={auImg} />
          </HStack>
        </Link>
      </div>
      <div className={css(styles.select_group)}>
        <Link onClick={() => handlePurchase('softbank')}>
          <HStack>
            <img className={css(styles.select_check)} src={checkImg} />
            <img className={css(styles.select_logo_image)} src={softbankImg} />
          </HStack>
        </Link>
      </div>
      <div className={css(styles.select_group)}>
        <Link onClick={() => handlePurchase('paypay')}>
          <HStack>
            <img className={css(styles.select_check)} src={checkImg} />
            <img className={css(styles.paypay_logo_image)} src={paypayImg} />
          </HStack>
        </Link>
      </div>
      <CircularProgressModal isOpen={isProcessing} />
      <ConfirmPopupModal handlePopup={() => handleDialog()} text="エラーが発生しました。" isOpen={!!apiError} />
    </div>
  )
}

export default PaymentSelect
