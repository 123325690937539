import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'

import { AddCartIcon } from '@!/components/Icon'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { cartItemsAtom, createCartItemAtom, deleteCartItemAtom } from '@!/atoms/cartItemsAtom'
import { saveCartItemLoggedIn, saveCartItemNotLoggedIn } from '@!/Util/LocalStorageData'
import { Config } from '@!/config/config'
import { NormalButton, NormalIconButton } from '@!/components/Button'
import { ConfirmPopupModal } from '@!/components/Modal'
import { useCreateCartItem } from '@!/Hooks'

export const AddCartButton = ({ detail, iconOnly }) => {
  const [userHash] = useAtom(userHashAtom)
  const [cartItems] = useAtom(cartItemsAtom)
  const [, createCartItem] = useAtom(createCartItemAtom)
  const [, deleteCartItem] = useAtom(deleteCartItemAtom)
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')

  const [createCartItemMutation] = useCreateCartItem(userHash, detail)

  const [maximumNumberOfCarts, setMaximumNumberOfCarts] = useState(Config.maximumNumberOfCartsNotLoggedIn)
  useEffect(() => {
    userHash ? setMaximumNumberOfCarts(Config.maximumNumberOfCartsLoggedIn) : setMaximumNumberOfCarts(Config.maximumNumberOfCartsNotLoggedIn)
  }, [userHash])

  useEffect(() => {
    if (!createCartItemMutation.isSuccess) return

    updateCartItem()
  }, [createCartItemMutation.isSuccess])

  useEffect(() => {
    if (!createCartItemMutation.isError) return

    openConfirmPopup('カートへの追加に失敗しました。')
  }, [createCartItemMutation.isError])

  const openConfirmPopup = (message) => {
    setConfirmMessage(message)
    setShowConfirmPopup(true)
  }

  const updateCartItem = () => {
    if (userHash) {
      // ログイン時
      saveCartItemLoggedIn(detail)
    } else {
      // 非ログイン時
      saveCartItemNotLoggedIn(detail)
    }

    // atomの更新
    cartItems.forEach((cartItem) => {
      if (cartItem.distribution_id === detail.distribution_id) {
        deleteCartItem()
      }
    })
    createCartItem(detail)
  }

  const handleAddCartItem = async (event) => {
    event.preventDefault()

    // 追加前のカート数と最大登録可能件数の比較
    if (cartItems.length >= maximumNumberOfCarts) {
      openConfirmPopup(`カートに追加できるのは最大${maximumNumberOfCarts}件です。`)
      return
    }

    // ログイン時
    if (userHash) {
      createCartItemMutation.mutate()
      return
    }

    updateCartItem()
  }

  return (
    <>
      {iconOnly ? (
        <NormalIconButton icon={<AddCartIcon width="1.5em" height="1.5em" />} onClick={handleAddCartItem} aria-label="カートに追加" />
      ) : (
        <NormalButton onClick={(event) => handleAddCartItem(event)} width="100%" height="37px" rightIcon={<AddCartIcon width="1.5em" height="1.5em" ml={-1} />}>
          カートに入れる
        </NormalButton>
      )}
      <ConfirmPopupModal
        text={confirmMessage}
        isOpen={showConfirmPopup}
        handlePopup={() => {
          setShowConfirmPopup(false)
        }}
      />
    </>
  )
}

AddCartButton.propTypes = {
  detail: PropTypes.object.isRequired,
  iconOnly: PropTypes.bool,
}

AddCartButton.defaultProps = {
  iconOnly: false,
}
