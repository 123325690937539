import { useState, useRef, useEffect } from 'react'
import { useMutation } from 'react-query'
import { css } from 'aphrodite'
import { styles } from '../styles/InquiryStyle'
import { CircularProgressModal, ConfirmPopupModal, YesNoPopupModal } from '@!/components/Modal'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { postInquiryApi } from '@!/Api/Inquiry'

const Inquiry = () => {
  const initValidateStatus = { result: false, data: {}, errors: [] }
  const [validateStatus, setValidateStatus] = useState(initValidateStatus)
  const [isApiProcessing, setIsApiProcessing] = useState(false)
  const [postResult, setPostResult] = useState('')
  const postInquiryMutation = useMutation({
    mutationFn: (params) => postInquiryApi(params),
    onSuccess: () => {
      setPostResult('success')
    },
    onError: () => {
      setPostResult('fail')
      throw new Error('お問い合わせの送信に失敗しました。')
    },
  })

  const nameInput = useRef(null)
  const emailInput = useRef(null)
  const titleInput = useRef(null)
  const descriptionInput = useRef(null)
  const screenDetailInput = useRef(null)
  const operationDetailInput = useRef(null)
  const handleSubmit = (e) => {
    e.preventDefault()
    let name = nameInput.current.value,
      email = emailInput.current.value,
      title = titleInput.current.value,
      description = descriptionInput.current.value,
      screen = screenDetailInput.current.value,
      operation = operationDetailInput.current.value
    let errorTexts = []
    if (!name) {
      errorTexts.push('お名前を入力してください。')
    }

    if (!email) {
      errorTexts.push('メールアドレスを入力してください。')
    } else if (!email.match(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i)) {
      errorTexts.push('メールアドレスの形式が不正です。')
    }

    if (!description) {
      errorTexts.push('お問い合わせ内容を入力してください。')
    } else if (description.length > 1000) {
      errorTexts.push('お問い合わせ内容は1000文字以下にしてください。')
    }

    if (screen && screen.length > 1000) {
      errorTexts.push('問題が発生した画面については1000文字以下にしてください。')
    }

    if (operation && operation.length > 1000) {
      errorTexts.push('行なった操作については1000文字以下にしてください。')
    }

    if (errorTexts.length == 0) {
      setValidateStatus({
        result: true,
        data: {
          name: name,
          email: email,
          title: title,
          description: description,
          screenDetail: screen,
          operationDetail: operation,
        },
      })
    } else {
      setValidateStatus({ result: false, errors: errorTexts })
      window.scrollTo(0, 0)
    }

    e.preventDefault()
  }

  const handleCancelDialog = () => {
    setValidateStatus(initValidateStatus)
  }

  const handlePost = async (e) => {
    e.preventDefault()
    setIsApiProcessing(true)
    try {
      await postInquiryMutation.mutateAsync({
        ...validateStatus.data,
        userAgent: navigator.userAgent,
      })
    } catch (error) {
      setPostResult('fail')
    }
    setValidateStatus(initValidateStatus)
    setIsApiProcessing(false)
  }

  const handleDialog = (isSuccess) => {
    if (isSuccess) {
      location.href = '/'
    }
    setPostResult('')
    setValidateStatus(initValidateStatus)
  }

  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お問い合わせ' }])
  }, [])

  return (
    <div className={css(styles.inquiry_main_container)}>
      {validateStatus.errors?.length > 0 ? (
        <div style={{ color: '#f00', border: '1px solid #f00' }}>
          {validateStatus.errors.map((error, index) => (
            <p key={index} style={{ margin: '6px' }}>
              {error}
            </p>
          ))}
        </div>
      ) : null}
      <YesNoPopupModal
        title={'送信します。よろしいですか？'}
        handleCancel={() => handleCancelDialog()}
        handleSubmit={(e) => handlePost(e)}
        cancelTitle={'キャンセル'}
        submitTitle={'送信する'}
        isOpen={validateStatus.result}
      />
      <CircularProgressModal isOpen={isApiProcessing} />
      <ConfirmPopupModal text="送信しました。" handlePopup={() => handleDialog(true)} isOpen={postResult === 'success'} />
      <ConfirmPopupModal text="送信できませんでした。" handlePopup={() => handleDialog(false)} isOpen={postResult === 'fail'} />
      <p className={css(styles.inquiry_help)}>
        よくある質問は
        <a href="/static_page/help" className={css(styles.inquiry_help_link)}>
          こちら
        </a>
        をご確認ください。
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <label htmlFor="name" className={css(styles.inquiry_label)}>
            お名前<span className={css(styles.inquiry_required)}>＊必須</span>
          </label>
          <input type="name" className={css(styles.inquiry_input)} ref={nameInput} />
        </div>
        <div>
          <label htmlFor="email" className={css(styles.inquiry_label)}>
            メールアドレス<span className={css(styles.inquiry_required)}>＊必須</span>
          </label>
          <input type="email" placeholder="email@example.com" className={css(styles.inquiry_input)} ref={emailInput} />
        </div>
        <div>
          <label htmlFor="title" className={css(styles.inquiry_label)}>
            タイトル
          </label>
          <input type="title" className={css(styles.inquiry_input)} ref={titleInput} />
        </div>
        <div>
          <label htmlFor="description" className={css(styles.inquiry_label)}>
            お問い合わせ内容(1000文字以下)<span className={css(styles.inquiry_required)}>＊必須</span>
          </label>
          <textarea type="description" className={css(styles.inquiry_textarea)} ref={descriptionInput} />
        </div>
        <div>
          <label htmlFor="screenDetail" className={css(styles.inquiry_label)}>
            問題が発生した画面を入力してください(1000文字以下)
          </label>
          <textarea type="screen_detail" className={css(styles.inquiry_textarea)} ref={screenDetailInput} />
        </div>
        <div>
          <label htmlFor="operationDetail" className={css(styles.inquiry_label)}>
            行なった操作を入力してください(1000文字以下)
          </label>
          <textarea type="operationDetail" className={css(styles.inquiry_textarea)} ref={operationDetailInput} />
        </div>

        <div className={css(styles.inquiry_button_box)}>
          <button type="submit" className={css(styles.inquiry_button)}>
            送信
          </button>
        </div>
      </form>
    </div>
  )
}

export default Inquiry
