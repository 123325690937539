import { useEffect, useState, useMemo } from 'react'
import { useAtom } from 'jotai'

import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Box, Text, Link, VStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { bookDetailsApi } from '@!/Api/StoreBook'
import { bookDetailsKeys } from '@!/Api/queryKeys'
import { useSearchCard, useAppCookies, usePurchase, usePointBackMeasure, useCartItems, useCalculatePointBack, useNavigationHandler } from '@!/Hooks'

import { getPreviousPaymentMethod, savePreviousPaymentMethod } from '@!/Util/LocalStorageData'

import PurchaseSummary from '@!/Store/Common/components/PurchaseSummary'
import CampaignPointSummary from '@!/Store/Common/components/CampaignPointSummary'
import PaymentMethod from '@!/Store/Purchase/components/PaymentMethod'
import PointUsageForm from '@!/Store/Purchase/components/PointUsageForm'
import CouponForm from '@!/Store/Purchase/components/CouponForm'
import PurchaseDetails from '@!/Store/Purchase/components/PurchaseDetails'
import { ConfirmPopupModal, CircularProgressModal } from '@!/components/Modal'
import { LessCoinsPopupModal } from '@!/Store/Purchase/components'
import { PrimaryButton } from '@!/components/Button'
import { userHashAtom, userUsableCoinsAtom, userPointsAtom } from '@!/atoms/userInfoAtom'
import { cardInfoAtom } from '@!/atoms/paymentAtom'
import { calculatePurchasePrices } from '@!/Store/Purchase/utils/calculatePurchasePrices'

const BookPurchase = ({ bookId, tokenApiUrl, shopId }) => {
  const navigate = useNavigate()
  const [userUsableCoins] = useAtom(userUsableCoinsAtom)
  const [userPoints] = useAtom(userPointsAtom)
  const [userHash] = useAtom(userHashAtom)
  const previousPaymentMethod = getPreviousPaymentMethod()
  const { removeCoinCountsCookie, removePointCountsCookie } = useAppCookies()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [books, setBooks] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(previousPaymentMethod || 'coin')
  const [spendPoints, setSpendPoints] = useState(false)
  const [pointValue, setPointValue] = useState('')
  const [openPopupType, setOpenPopupType] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  const [currentCoupon, setCurrentCoupon] = useState({})
  const { basePrice, couponAppliedPrice, totalPrice, taxExcludedPrice } = calculatePurchasePrices(books, isCouponApplied, currentCoupon, pointValue)

  const { data: pointBackMeasure, isError: pointBackMeasureError, isLoading: pointBackMeasureLoading } = usePointBackMeasure()
  const [cardInfo, setCardInfo] = useAtom(cardInfoAtom)
  const [searchCardMutation, cardInfoError] = useSearchCard(userHash)

  const { data: cartItems, error: cartItemsError, isLoading: cartItemsLoading } = useCartItems(userHash)
  const {
    data: bookDetails,
    error: bookDetailsError,
    isLoading: bookDetailsLoading,
  } = useQuery({
    queryKey: bookDetailsKeys.bookDetails(bookId),
    queryFn: () => bookDetailsApi(bookId),
    enabled: !!bookId,
    // https://github.com/AmaziaInc/MangaBANG-WEB/issues/2417
    // 以下、自動fetch防止のための設定 (価格更新を避けたい)
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  const previousPage = bookId ? 'bookDetailPage' : 'cartPage'
  const [purchaseMutation, redirectUrl, apiError] = usePurchase(previousPage)

  const pointBackWithoutCoupon = useCalculatePointBack(books, pointBackMeasure, pointValue, isCouponApplied)

  const pointBack = useMemo(() => {
    if (isCouponApplied) {
      // クーポン利用の場合、還元施策対象外の作品があるかは考慮しない
      // 割引き後の税抜き価格 x 還元率
      return Math.floor(taxExcludedPrice * (pointBackMeasure.point_back_rate / 100))
    }

    // クーポン利用がない場合
    return pointBackWithoutCoupon
  }, [isCouponApplied, taxExcludedPrice, pointBackMeasure, pointBackWithoutCoupon])

  const openPurChaseErrorPopup = (message) => {
    setAlertMessage(message)
    setOpenPopupType('purchaseError')
  }

  const { redirectToLoginWithToast } = useNavigationHandler()

  useEffect(() => {
    if (userHash === undefined) {
      redirectToLoginWithToast()
    }
  }, [userHash])

  useEffect(() => {
    setIsLoading(pointBackMeasureLoading || searchCardMutation.isLoading || cartItemsLoading || bookDetailsLoading)

    let errors = []
    if (pointBackMeasureError) {
      errors.push(pointBackMeasureError)
    }
    if (cardInfoError) {
      errors.push(cardInfoError)
    }
    if (cartItemsError) {
      errors.push(cartItemsError)
    }
    if (bookDetailsError) {
      errors.push(bookDetailsError)
    }

    if (errors.length > 0) {
      setError(errors)
    }
  }, [pointBackMeasure, searchCardMutation.isLoading, cartItemsLoading, bookDetailsLoading])

  useEffect(() => {
    if (bookId) {
      setBooks(bookDetails)
    } else {
      setBooks(cartItems)
    }
  }, [bookId, bookDetails, cartItems])

  useEffect(() => {
    if (purchaseMutation.isSuccess) {
      savePreviousPaymentMethod(selectedPaymentMethod)
      removeCoinCountsCookie()
      removePointCountsCookie()

      window.location.href = redirectUrl
      purchaseMutation.reset()
    }
  }, [purchaseMutation.isSuccess])

  useEffect(() => {
    if (!apiError) return

    if (redirectUrl) {
      window.location.href = redirectUrl
      purchaseMutation.reset()
    } else {
      openPurChaseErrorPopup(apiError)
    }
  }, [apiError])

  if (error) {
    return <div>Error</div>
  }

  const pageBack = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  const handleGoCoinPurchase = () => {
    window.location.href = '/user/payment'
  }

  const openAlertPopup = (message) => {
    setAlertMessage(message)
    setOpenPopupType('alert')
  }

  const closePopup = () => {
    setOpenPopupType('')
  }

  const openLessCoinsPopup = () => {
    setOpenPopupType('lessCoins')
  }

  const handlePurchase = async (e) => {
    e.preventDefault()

    if (selectedPaymentMethod === 'coin' && userUsableCoins < totalPrice) {
      openLessCoinsPopup()
      return
    }

    if (selectedPaymentMethod === 'credit' && (!cardInfo || Object.keys(cardInfo).length === 0)) {
      openAlertPopup('クレジットカード情報を登録してください。')
      return
    }

    if (spendPoints) {
      if (pointValue === '' || Number(pointValue) === 0) {
        openAlertPopup('ポイントを入力してください。')
        return
      }
      if (Number(pointValue) > userPoints) {
        openAlertPopup('ポイントが足りません。')
        return
      }
    }

    const paymentType = totalPrice === 0 ? 'none' : selectedPaymentMethod

    const purchaseParams = {
      payment_type: paymentType,
      use_point_count: pointValue,
      total_price: totalPrice,
      purchase_details: books,
      point_back: pointBack,
    }

    if (isCouponApplied) {
      purchaseParams.coupon_code = currentCoupon.code
    }

    purchaseMutation.mutate(purchaseParams)
  }

  const closeErrorPopup = () => {
    console.log(error)
    setError(null)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgressModal isOpen={isLoading} />
      ) : error ? (
        <ConfirmPopupModal isOpen={!!error} text="エラーが発生しました。" handlePopup={closeErrorPopup} />
      ) : (
        <Box fontSize="13px" p={4} bg="#f3f3f8" maxW="800px" mx="auto">
          <Box p={4} bg="white" borderWidth={1} borderRadius="md">
            <PurchaseSummary totalPrice={totalPrice} basePrice={basePrice} isCouponApplied={isCouponApplied} />
            {Object.keys(pointBackMeasure).length > 0 && <CampaignPointSummary pointBack={pointBack} pointBackRate={pointBackMeasure.point_back_rate} />}
          </Box>
          <Text fontSize="16px" fontWeight="bold" color="black" py={3}>
            決済手段
          </Text>
          <PaymentMethod
            cardInfo={cardInfo}
            setCardInfo={setCardInfo}
            coins={userUsableCoins}
            previousPaymentMethod={previousPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            tokenApiUrl={tokenApiUrl}
            shopId={shopId}
            handleGoCoinPurchase={handleGoCoinPurchase}
            isDisabled={totalPrice === 0}
          />
          <Text fontSize="16px" fontWeight="bold" color="black" py={3}>
            クーポン
          </Text>
          <Box p={4} bg="white" borderWidth={1} borderRadius="md">
            <CouponForm
              setIsCouponApplied={setIsCouponApplied}
              setCurrentCoupon={setCurrentCoupon}
              setPointValue={setPointValue}
              setSpendPoints={setSpendPoints}
              basePrice={basePrice}
            />
          </Box>
          <Text fontSize="16px" fontWeight="bold" color="black" py={3}>
            ポイント利用
          </Text>
          <Box p={4} bg="white" borderWidth={1} borderRadius="md">
            <PointUsageForm
              points={userPoints}
              pointValue={pointValue}
              setPointValue={setPointValue}
              spendPoints={spendPoints}
              setSpendPoints={setSpendPoints}
              currentPrice={couponAppliedPrice || basePrice}
              isDisabled={basePrice === 0 || couponAppliedPrice === 0}
            />
          </Box>
          <Text fontSize="16px" fontWeight="bold" color="black" py={3}>
            購入明細
          </Text>
          <Box p={4} bg="white" borderWidth={1} borderRadius="md">
            <PurchaseDetails books={books} />
          </Box>
          <Text fontSize="16px" fontWeight="bold" color="black" py={3}></Text>
          <Box p={4} bg="white" borderWidth={1} borderRadius="md">
            <PurchaseSummary totalPrice={totalPrice} basePrice={basePrice} isCouponApplied={isCouponApplied} />
            {Object.keys(pointBackMeasure).length > 0 && <CampaignPointSummary pointBack={pointBack} pointBackRate={pointBackMeasure.point_back_rate} />}
            <Box textAlign="center" mt={3}>
              <PrimaryButton onClick={handlePurchase} isDisabled={purchaseMutation.isLoading} width="100%">
                決済を完了する
              </PrimaryButton>
            </Box>
          </Box>
          <VStack my={3} spacing={0}>
            <Link href="/static_page/specified_commercial_transaction" isExternal mb="1em">
              特定商取引法の表示
            </Link>
            <Link href="#" onClick={pageBack}>
              {'前のページへ戻る'}
            </Link>
          </VStack>
          <ConfirmPopupModal isOpen={openPopupType === 'alert'} text={alertMessage} handlePopup={closePopup} />
          <ConfirmPopupModal isOpen={openPopupType === 'purchaseError'} text={alertMessage} handlePopup={closePopup} />
          <LessCoinsPopupModal isOpen={openPopupType === 'lessCoins'} handleGoCoinPurchase={handleGoCoinPurchase} handleClose={closePopup} />
        </Box>
      )}
    </>
  )
}

BookPurchase.propTypes = {
  bookId: PropTypes.string,
  tokenApiUrl: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
}

export default BookPurchase
