import { Stack, Box } from '@chakra-ui/react'
import { PropTypes } from 'prop-types'
import { Book, BookTitle } from './ListItem'
import { ScrollableContent } from '@!/Store/Home/components'

export const NewScrollListTitles = ({ titles, type = 'book', sectionTitle }) => {
  const items = titles.map((title, index) => {
    if (type === 'book') {
      return (
        <Book key={`list-${index}-${title.id}`} title={title.name} distributionId={title.id} imageUrl={title.image_url} sectionTitle={sectionTitle} w="100px" />
      )
    } else {
      return (
        <Box key={`list-${index}-${title.title_id}`} mr={3}>
          <BookTitle title={title.name} titleId={title.title_id} imageUrl={title.image_url} w="100px" />
        </Box>
      )
    }
  })

  return (
    <ScrollableContent>
      <Stack direction="row" spacing={0}>
        {items}
      </Stack>
    </ScrollableContent>
  )
}

NewScrollListTitles.propTypes = {
  titles: PropTypes.array.isRequired,
  type: PropTypes.string,
  sectionTitle: PropTypes.string,
}
