import { useRef, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Box, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

import { ClearSearchButton } from './'

export const SearchBox = ({ defaultValue, handleSearch, placeholder }) => {
  const keywordInput = useRef(null)
  // NOTE: デフォルト値が指定されていたらクリアボタンを初期表示、なければ非表示
  const [clearButtonVisible, setClearButtonVisible] = useState(defaultValue && defaultValue.length > 0)

  const handleSubmit = (e) => {
    e.preventDefault()
    const keyword = keywordInput.current.value
    if (keyword.length > 0) {
      handleSearch(keyword)
    }
  }

  const handleClear = () => {
    keywordInput.current.value = ''
    setClearButtonVisible(false)
  }

  const toggleClearButton = () => {
    if (keywordInput.current.value.length > 0 && !clearButtonVisible) {
      setClearButtonVisible(true)
    } else if (keywordInput.current.value.length == 0 && clearButtonVisible) {
      setClearButtonVisible(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <InputGroup
          sx={{
            '[type="search"]': {
              WebkitAppearance: 'none',
            },
          }}
        >
          <InputLeftElement bg="bg.footer" borderColor="bg.footer" rounded="md" borderTopRightRadius="0px" borderBottomRightRadius="0px">
            <SearchIcon color="label.white" />
          </InputLeftElement>
          {clearButtonVisible && (
            <InputRightElement>
              <ClearSearchButton onClick={handleClear} />
            </InputRightElement>
          )}
          <Input
            ref={keywordInput}
            bg="bg.secondary"
            type="search"
            name="q"
            defaultValue={defaultValue}
            placeholder={placeholder}
            fontSize="sm"
            borderColor="divider.dark"
            borderLeft="none"
            focusBorderColor="divider.dark"
            boxSizing="border-box"
            onChange={toggleClearButton}
            paddingLeft="3rem"
            _focusVisible={{
              zIndex: 1,
              borderColor: 'divider.dark',
              // #B8B8BD = 'divider.dark'
              boxShadow: '0 0 0 1px #B8B8BD inset',
            }}
            css={{
              '::-webkit-search-cancel-button': {
                WebkitAppearance: 'none',
              },
            }}
          />
        </InputGroup>
      </Box>
    </form>
  )
}

SearchBox.propType = {
  defaultValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}
