import PropTypes from 'prop-types'
import { Tag } from '@chakra-ui/react'

export const TagItem = ({ name, ...props }) => (
  <Tag m="2px" color="label.primary" backgroundColor="bg.tertiary" fontWeight="semibold" borderRadius="full" fontSize="sm" px={2} py={1.5} {...props}>
    {name}
  </Tag>
)

TagItem.propTypes = {
  name: PropTypes.string.isRequired,
}
