import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'

import { ScrollableFeatureList } from '@!/Store/Common/components'
import { PointBackBanner } from '@!/components/Banner'
import { BookList, HeaderInfo } from '@!/Store/FeatureList/components'
import { parsePermitTime } from '@!/Store/Common/util/time'

import { Box, Divider, Stack } from '@chakra-ui/react'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { SearchForm } from '@!/Store/Common/containers'

export const StoreFeatureList = ({ items, currentTime }) => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: items.title }])
  }, [items.title])

  const time = Date.parse(currentTime)
  const books = useMemo(() => {
    return items.books.filter((book) => {
      const permitStart = parsePermitTime(book.permit_start)
      const permitEnd = parsePermitTime(book.permit_end, true)

      return permitStart < time && time < permitEnd
    })
  }, [items.books, time])
  const features = items.other_features || []

  return (
    <>
      <PointBackBanner />
      <SearchForm />
      <Box px="1rem">
        <HeaderInfo title={items.title} />
        <Divider />
        <BookList books={books} />
        <Divider />
      </Box>
      <Stack py="40px" spacing={0}>
        {features.map((feature) => {
          return <ScrollableFeatureList key={feature.pathname} title={feature.title} pathname={feature.pathname} books={feature.book_titles} />
        })}
      </Stack>
    </>
  )
}

StoreFeatureList.propTypes = {
  items: PropTypes.object.isRequired,
  currentTime: PropTypes.string.isRequired,
}

export default StoreFeatureList
