import { useState, useMemo, useEffect } from 'react'
import { useAtom } from 'jotai'
import { css } from 'aphrodite'

import { styles } from '@!/User/Payment/styles/PaymentStyle'
import { Link, useNavigate } from 'react-router-dom'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { numberWithDelimiter } from '@!/Util/String'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'
import { paymentListAtom, newCardTokenAtom, cardInfoAtom, newCardInfoAtom, listIndexAtom, apiTimeAtom, initPaymentAtom } from '@!/atoms/paymentAtom'
import { usePaymentSaveCard, usePaymentExecPurchase, useJumpLastUrl } from '@!/Hooks'

export const PaymentConfirm = () => {
  const jumpLastUrl = useJumpLastUrl()
  const navigate = useNavigate()
  const PurchaseStatus = {
    IDLE: null,
    NOT_COMPLETE: 0,
    COMPLETE: 1,
  }

  const [listIndex] = useAtom(listIndexAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const [cardInfo] = useAtom(cardInfoAtom)
  const [newCardToken] = useAtom(newCardTokenAtom)
  const [newCardInfo] = useAtom(newCardInfoAtom)
  const [apiTime] = useAtom(apiTimeAtom)
  const [, initPayment] = useAtom(initPaymentAtom)
  const [purchaseStatus, setPurchaseStatus] = useState(PaymentConfirm.IDLE)
  const [isProcessing, setIsProcessing] = useState(false)
  const initApiError = { error: null, errorInfo: null }
  const [apiError, setApiError] = useState(initApiError)

  const [isInValidPaymentListItem] = useValidListItemCheck()
  const coin = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][0]
  }, [isInValidPaymentListItem, paymentList, listIndex])
  const price = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][1]
  }, [isInValidPaymentListItem, paymentList, listIndex])

  useEffect(() => {
    if (isInValidPaymentListItem) goPurchaseTop()
  }, [isInValidPaymentListItem])

  const [saveCardMutation, saveCardApiError] = usePaymentSaveCard()
  const [execPurchaseMutation, execPurchaseApiError] = usePaymentExecPurchase()

  // save_card APIの実行結果後処理
  useEffect(() => {
    if (!saveCardMutation.isSuccess) return

    setApiError(initApiError)
    execPurchaseMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
  }, [saveCardMutation.isSuccess])

  useEffect(() => {
    if (!saveCardMutation.isError) return

    setApiError(saveCardApiError)
    setIsProcessing(false)
  }, [saveCardMutation.isError])

  // exec_purchase APIの実行結果後処理
  useEffect(() => {
    if (!execPurchaseMutation.isSuccess) return

    setPurchaseStatus(PurchaseStatus.COMPLETE)
    setApiError(initApiError)
    setIsProcessing(false)
  }, [execPurchaseMutation.isSuccess])

  useEffect(() => {
    if (!execPurchaseMutation.isError) return

    setPurchaseStatus(PurchaseStatus.NOT_COMPLETE)
    setApiError(execPurchaseApiError)
    setIsProcessing(false)
  }, [execPurchaseMutation.isError])

  const handleSubmit = () => {
    if (isProcessing) return

    setIsProcessing(true)

    if (newCardToken) {
      const update = cardInfo && Object.keys(cardInfo).length != 0 ? true : false
      saveCardMutation.mutate({ token: newCardToken, isUpdate: update })
      return
    }

    execPurchaseMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
  }

  const handleComplete = () => {
    initPayment()
    setPurchaseStatus(PaymentConfirm.IDLE)
    jumpLastUrl()
  }

  const goPurchaseTop = () => {
    initPayment()
    setPurchaseStatus(PaymentConfirm.IDLE)
  }

  const pageBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  const apiErrorText = useMemo(() => {
    if (apiError.error === null) return ''

    if (apiError.error.indexOf('card_info') != -1) {
      const errorCode = apiError.errorInfo ? `(ErrorCode:${apiError.errorInfo})` : ''
      return '決済に失敗しました。入力内容をご確認ください' + errorCode
    } else if (apiError.error.indexOf('token_expire') != -1) {
      return '決済に失敗しました。再度入力をお願いします。'
    } else if (apiError.error.indexOf('coin_add') != -1) {
      return 'コイン付与に失敗しました。'
    }
    return 'エラーが発生しました。'
  }, [apiError])

  const handleApiErrorPopup = () => {
    if (apiError.error === null) return

    if (apiError.error.indexOf('token_expire') != -1) {
      goPurchaseTop()
      return
    } else if (apiError.error.indexOf('coin_add') != -1) {
      goPurchaseTop()
      return
    }

    setPurchaseStatus(PurchaseStatus.IDLE)
    setApiError(initApiError)
  }

  const currentCard = newCardToken ? newCardInfo : cardInfo

  const cardBlock = (
    <div>
      {!newCardToken ? (
        <div className={css(styles.confirm_before_block)}>
          <p className={css(styles.confirm_before_title)}>前回使用したカード</p>
          <Link className={css(styles.confirm_card_other_link)} to="/new_card">
            <span className={css(styles.confirm_card_other_text)}>他のカードを使う</span>
          </Link>
        </div>
      ) : null}
      <div className={css(styles.confirm_credit_card)}>
        <div className={css(styles.confirm_credit_card_label)}>クレジットカード番号</div>
        <p className={css(styles.confirm_credit_card_data)}>{currentCard?.number}</p>
      </div>
      <div className={css(styles.confirm_credit_card)}>
        <label className={css(styles.confirm_credit_card_label)}>カード名義</label>
        <p className={css(styles.confirm_credit_card_data)}>{currentCard?.name}</p>
      </div>
      <div className={css(styles.confirm_credit_card)}>
        <label className={css(styles.confirm_credit_card_label)}>有効期限</label>
        <p className={css(styles.confirm_credit_card_data)}>{currentCard?.year + ' / ' + currentCard?.month}</p>
      </div>
    </div>
  )

  return (
    <div className={css(styles.credit_card_main)}>
      <div className={css(styles.confirm_card_info)}>
        <form>
          <div className={css(styles.confirm_form_group)}>
            <label className={css(styles.confirm_card_label)}>購入コイン数</label>
            <p className={css(styles.credit_card_coin)}>{coin}</p>
          </div>

          <div className={css(styles.confirm_form_group)}>
            <label className={css(styles.confirm_card_label)}>お支払い金額</label>
            <p className={css(styles.credit_card_price)}>￥{numberWithDelimiter(price)}</p>
          </div>
          {cardBlock}
          <div className={css(styles.confirm_back)}>
            <a className={css(styles.confirm_back_link)} href="#" onClick={(e) => pageBack(e)}>
              {'< 前のページに戻る'}
            </a>
          </div>
          <div className={css(styles.confirm_form_buttons)}>
            <button type="button" className={css(styles.credit_card_submit)} onClick={(e) => handleSubmit(e)}>
              購入する
            </button>
          </div>
        </form>
      </div>
      <CircularProgressModal isOpen={isProcessing} />
      <ConfirmPopupModal handlePopup={handleApiErrorPopup} text={apiErrorText} isOpen={!!apiError.error} />
      <ConfirmPopupModal handlePopup={() => handleComplete()} text="お支払いが完了しました。" isOpen={purchaseStatus == PurchaseStatus.COMPLETE} />
    </div>
  )
}

export default PaymentConfirm
