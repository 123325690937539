import { StyleSheet, css } from 'aphrodite'

export const PageButtons = (props) => {
  const { currentPage, pageBack, pageNext, pageNumbers } = props

  if (!pageNumbers || Object.keys(pageNumbers).length === 0) {
    return null
  }

  let pageButtons = []
  // 1つ前のページへボタン
  if (pageBack) {
    pageButtons.push(
      <a key={-1} href={pageBack} className={css(styles.store_kana_page_item_link)}>
        <span className={css(styles.store_kana_page_item)}>{'<<'}</span>
      </a>,
    )
  }

  Object.keys(pageNumbers).forEach((num) => {
    if (num == currentPage) {
      pageButtons.push(
        <span key={num} className={css(styles.store_kana_page_item)} style={{ background: '#EFEFF1' }}>
          {num}
        </span>,
      )
    } else {
      pageButtons.push(
        <a key={num} href={pageNumbers[num]} className={css(styles.store_kana_page_item_link)}>
          <span className={css(styles.store_kana_page_item)}>{num}</span>
        </a>,
      )
    }
  })

  // 1つ先のページへボタン
  if (pageNext) {
    pageButtons.push(
      <a key={-2} href={pageNext} className={css(styles.store_kana_page_item_link)}>
        <span className={css(styles.store_kana_page_item)}>{'>>'}</span>
      </a>,
    )
  }

  return (
    <div className={css(styles.store_kana_page_block)}>
      <div className={css(styles.store_kana_page)}>{pageButtons}</div>
    </div>
  )
}

const styles = StyleSheet.create({
  store_kana_page_block: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '18px',
  },
  store_kana_page: {
    display: 'inline-block',
    width: '100%',
    maxWidth: '400px',
    color: '#707172',
    fontSize: '12px',
  },
  store_kana_page_item: {
    padding: '6px 8px',
    border: '1px solid #898989',
    borderRadius: '4px',
    margin: '0 2px',
  },
  store_kana_page_item_link: {
    color: '#707172',
    textDecoration: 'none',
  },
})
