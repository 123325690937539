import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'

const allTabs = [
  { name: 'home', label: 'HOME', safeSearchable: true, url: '/' },
  { name: 'man', label: '少年・青年', safeSearchable: true, url: '/store/man' },
  { name: 'woman', label: '少女・女性', safeSearchable: true, url: '/store/woman' },
  { name: 'tl', label: 'TL', safeSearchable: false, url: '/store/tl' },
  { name: 'bl', label: 'BL', safeSearchable: false, url: '/store/bl' },
  { name: 'adult', label: 'オトナ', safeSearchable: false, url: '/store/adult' },
]

export const useTabs = (tabName) => {
  const [safeSearch] = useAtom(safeSearchAtom)
  const tab = useMemo(() => allTabs.find((tab) => tab.name === tabName) || allTabs.find((tab) => tab.name === 'home'), [tabName])
  const filteredTabs = useMemo(() => (safeSearch ? allTabs.filter((tab) => tab.safeSearchable) : allTabs), [safeSearch])

  const genreName = tab.name === 'home' ? null : tab.name
  const genreLabel = genreName ? `${tab.label}ジャンル` : '全ジャンル'

  const currentTab = {
    name: tab.name,
    genreName: genreName,
    genreLabel: genreLabel,
    label: tab.label,
    url: tab.url,
    safeSearchable: tab.safeSearchable,
  }

  return { currentTab, tabs: filteredTabs }
}
