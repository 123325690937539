import { useJumpLastUrl } from '@!/Hooks'
import { StyleSheet, css } from 'aphrodite'

export const PaymentComplete = (props) => {
  const jumpLastUrl = useJumpLastUrl()
  const { error, error_type, cancel } = props.params
  let text = 'コインの購入を完了しました。'
  if (cancel) {
    text = 'コインの購入をキャンセルしました。'
  } else if (error) {
    switch (error_type) {
      case 'coin_add_failure':
        text = '申し訳ありません、コインの付与に失敗しました。恐れ入りますが、メニューの「お問い合わせ」よりサポートまでご連絡ください。'
        break
      default:
        text = '決済に失敗しました。再度コイン購入を行ってください。'
    }
  }

  const styles = StyleSheet.create({
    main_container: {
      padding: '12px',
    },
    confirm_back: {
      margin: '24px auto',
      textAlign: 'center',
      border: '1px solid #EEE',
      background: '#EEE',
      borderRadius: '12px',
      width: '65%',
      padding: '12px 24px',
      boxShadow: '0 1px 6px rgba(36, 33, 33, 0.28)',
    },
    confirm_back_link: {
      color: '#484848',
    },
    payment_complete_text: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
    },
  })

  return (
    <div className={css(styles.main_container)}>
      <p className={css(styles.payment_complete_text)}>{text}</p>
      <div className={css(styles.confirm_back)}>
        <a className={css(styles.confirm_back_link)} href="#" onClick={jumpLastUrl}>
          {'元のページに戻る'}
        </a>
      </div>
    </div>
  )
}
